import React, { useContext, useState } from 'react';
import {
  ParagraphSearchbarFragmentFragment,
  TermCategory,
  TermCategoryFragmentFragment,
} from '../../../generated/types';
import useIsMobile from '../../../hooks/useIsMobile';
import { useStore } from '../../../store/provider';
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';
import Container from '../../Layout/Container';
import { HTMLText } from '../../Common/HTMLText';
import getIconForCategoryKey from '../../../utils/getIconForCategoryKey';
import BetterPlaceAutocomplete, {
  TransformedAutocompleteData,
} from '../../Common/BetterPlaceAutocomplete';
import { FilterIcon2, MapSearchIconAlt } from '../../../config/icons';
import Link from 'next/link';
import log from '../../../utils/logger';
import {
  applyMapParamToRoute,
  autocompleteResultToUrl,
} from '../../../utils/helper';
import { useRouter } from 'next/router';
import SearchContext from '../../../context/SearchContext';
import FilterDrawer from '../../Common/FilterDrawer';
import { observer } from 'mobx-react-lite';

export interface SearchbarProps {
  data: ParagraphSearchbarFragmentFragment;
}

const Searchbar: React.FC<SearchbarProps> = ({ data }) => {
  const isMobile = useIsMobile();
  const store = useStore();
  const router = useRouter();
  const context = useContext(SearchContext);
  const [radiusValue, setRadius] = useState<number>(
    context.form.radius ? parseInt(context.form.radius, 10) : 30
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const onOpenDrawer = () => setIsDrawerOpen(true);
  const onCloseDrawer = () => setIsDrawerOpen(false);

  const isMap =
    router.query && (router.query.karte === '' || router.query.karte === '1');

  const [
    activeCategory,
    setActiveCategory,
  ] = useState<TermCategoryFragmentFragment>(
    context.activeCategory || data.categories[0].category
  );

  const [
    selectedItem,
    setSelectedItem,
  ] = useState<TransformedAutocompleteData>();

  const getSearchUrl = (
    category: TermCategory,
    additionalProps = {},
    inputValue?: string
  ) => {
    if (selectedItem) {
      return autocompleteResultToUrl(
        category,
        selectedItem,
        radiusValue + '',
        {},
        context.isGroupSearch
      );
    } else {
      return autocompleteResultToUrl(
        category,
        selectedItem,
        radiusValue + '',
        {
          filter: router.query.filter,
          latitude: router.query.latitude,
          longitude: router.query.longitude,
          query: router.query.query,
          radius: radiusValue + '',
          ...additionalProps,
        },
        context.isGroupSearch,
        inputValue
      );
    }
  };

  const onSelectItem = (item: TransformedAutocompleteData) => {
    console.log('on select', item);
    setSelectedItem(item);
  };

  const onSubmit = (inputValue?: string) => {
    const searchUrl = getSearchUrl(activeCategory, {}, inputValue);
    if (searchUrl) {
      router.push(applyMapParamToRoute(searchUrl, isMap));
    }
  };

  const onChangeRadius = (val: string) => {
    setRadius(parseInt(val, 10));
  };

  const showRadius = Boolean(
    (selectedItem && selectedItem.type === 'geocode') ||
      (!selectedItem && context.form.location && context.form.radius)
  );

  // TODO: add form + form submit via enter
  // TODO: filter

  const MapButton = () => (
    <IconButton
      color="searchbar.iconButtonTextColor"
      onClick={() => {
        const searchUrl = getSearchUrl(activeCategory);
        if (searchUrl) {
          router.push(applyMapParamToRoute(searchUrl, true));
        }
      }}
      paddingX={2}
      minWidth="4.5rem"
      aria-label="Zur Kartenansicht"
      borderRadius="3xl"
      icon={<MapSearchIconAlt />}
    />
  );

  const FilterButton = () => (
    <Button marginRight={2} onClick={onOpenDrawer}>
      <FilterIcon2 />
      {store.search.activeFilters.length > 0 && (
        <Badge marginLeft={1} colorScheme="red">
          {store.search.activeFilters.length}
        </Badge>
      )}
    </Button>
  );

  const hotelCategory = data.categories.find(
    (searchCategory) => searchCategory.category.key === 'hotel'
  );

  return (
    <Box marginY={6} className="ParagraphSearchBar">
      {context.activeCategory && (
        <FilterDrawer
          open={isDrawerOpen}
          onClose={onCloseDrawer}
          category={context.activeCategory.frontendUrl || ''}
          activeFilters={store.search.activeFilters}
        />
      )}
      <Container>
        <Flex
          direction={{
            base: 'column',
            md: 'row',
          }}
        >
          <Box flex={1}>
            <HTMLText text={data.headline} />
            <Flex
              mt={4}
              flexDirection={{
                base: 'column',
                md: 'row',
              }}
            >
              <Flex
                order={{
                  base: 1,
                  md: 0,
                }}
                alignItems="center"
                justifyContent="space-between"
                mt={{
                  base: 4,
                  md: 0,
                }}
              >
                <ButtonGroup
                  spacing={0}
                  boxShadow="brand"
                  borderRadius="3xl"
                  borderWidth="2px"
                  borderColor="searchbar.borderColor"
                >
                  {data.categories.map((categoryMap, i) => {
                    const Icon = getIconForCategoryKey(
                      categoryMap.category.key!
                    );
                    const isActive =
                      activeCategory.id === categoryMap.category.id;

                    const onClickIcon = () => {
                      if (!data.categoriesLinkToSearchPage) {
                        setActiveCategory(categoryMap.category);
                      } else {
                        if (!categoryMap.category.searchPageUrl) {
                          log(
                            '[Searchbar] category has no searchPageUrl',
                            categoryMap.category.name
                          );
                        }
                      }
                    };

                    const IconButtonComponent = (
                      <IconButton
                        onClick={onClickIcon}
                        paddingX={2}
                        minWidth="3.5rem"
                        h="calc(2.5rem - 4px)"
                        variant="unstyled"
                        bg={isActive ? 'searchbar.buttonBg' : 'transparent'}
                        borderLeftRadius={i === 0 ? '3xl' : 'none'}
                        borderRightRadius={
                          i === data.categories.length - 1 ? '3xl' : 'none'
                        }
                        aria-label={categoryMap.title}
                        color={isActive ? '#fff' : 'textColor'}
                        icon={<Icon />}
                        _hover={{
                          bg: 'searchbar.buttonBg',
                          color: '#fff',
                        }}
                      />
                    );

                    return (
                      <Box key={categoryMap.category.id}>
                        <Tooltip
                          hasArrow
                          label={categoryMap.title}
                          aria-label={categoryMap.title}
                          zIndex={999}
                          placement="top"
                        >
                          <Box as="span">
                            {data.categoriesLinkToSearchPage &&
                            categoryMap.category.searchPageUrl ? (
                              <Link href={categoryMap.category.searchPageUrl}>
                                <a>{IconButtonComponent}</a>
                              </Link>
                            ) : (
                              IconButtonComponent
                            )}
                          </Box>
                        </Tooltip>
                      </Box>
                    );
                  })}
                </ButtonGroup>
                {data.showFilters && isMobile && <FilterButton />}
                {data.showMapButton && isMobile && (
                  <Box>
                    <MapButton />
                  </Box>
                )}
              </Flex>
              <Box
                order={0}
                flex={1}
                marginX={{
                  base: 0,
                  md: 4,
                }}
              >
                <BetterPlaceAutocomplete
                  category={activeCategory.frontendUrl!}
                  id={`place-autocomplete-${activeCategory.id}`}
                  initialValue={context.form.query}
                  placeholder={activeCategory.question}
                  onSelectItem={onSelectItem}
                  withSearchButton
                  onClickSearch={onSubmit}
                  radius={showRadius}
                  radiusValue={radiusValue + ''}
                  onChangeRadius={onChangeRadius}
                  inputProps={{
                    fontSize: {
                      base: 'sm',
                      md: 'md',
                    },
                    paddingLeft: {
                      base: '1rem',
                      md: '2rem',
                    },
                  }}
                  hideLeftIcon={isMobile}
                />
              </Box>
              <HStack spacing={4}>
                {data.showFilters && !isMobile && <FilterButton />}
                {data.showMapButton && !isMobile && <MapButton />}
              </HStack>
            </Flex>
          </Box>
          {data.showGroupSearch && hotelCategory && (
            <Box
              ml={{
                base: 0,
                md: 12,
              }}
              mt={{
                base: 4,
                md: 0,
              }}
            >
              <HTMLText text={data.groupSearchTitle} />
              <Box mt={4}>
                <Link
                  href={hotelCategory.category.groupSearchPageUrl || ''}
                  passHref
                >
                  <Button as="a" width="100%">
                    {data.groupSearchButtonTitle || 'Gruppensuche'}
                  </Button>
                </Link>
              </Box>
            </Box>
          )}
        </Flex>
      </Container>
    </Box>
  );
};

export default observer(Searchbar);
